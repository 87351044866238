<template>
    <a-table 
        :columns="columns"
        :loading="loading"
        :pagination="false"
        :row-key="record => record.id"
        :locale="{
            emptyText: 'Нет данных'
        }"
        :data-source="list">
        <template 
            slot="currency" 
            slot-scope="text">
            <template v-if="text">
                {{ text.name }}
            </template>
        </template>
        <template 
            slot="actions" 
            slot-scope="text">
            <a-button 
                type="dashed"
                class="icon_button"
                @click="getAct(text)">
                <i class="fi fi-rr-download"></i>
            </a-button>
        </template>
    </a-table>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            actLoading: {},
            page: 1,
            list: [],
            columns: [
                {
                    title: 'Название',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Валюта',
                    dataIndex: 'currency',
                    key: 'currency',
                    scopedSlots: { customRender: 'currency' }
                },
                {
                    title: '',
                    dataIndex: 'id',
                    key: 'id',
                    scopedSlots: { customRender: 'actions' }
                }
            ]
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async getAct(id) {
            try {
                this.loading = true
                const { data } = await this.$http.get(`/crm/contract/${id}/get_file/`, {
                    responseType: 'blob'
                })
                if(data) {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Акт сверки от ${this.$moment().format('DD-MM-YYYY')}.zip`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            } finally {
                this.loading = false
            }
        },
        async getList() {
            try {
                this.loading = true
                const { data } = await this.$http.get('/catalogs/contracts/', {
                    params: {
                        page: this.page
                    }
                })
                if(data)
                    this.list = data.results
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.icon_button {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
}
</style>